<script>
import Layout from "../../layouts/main";
import CouponsServiceApi from "@/service/api/coupons";

export default {
  components: { Layout },
  data() {
    return {
      couponsData: null,
      fields: [
        { key: "id", sortable: false },
        { key: "code", sortable: false },
        { key: "value", sortable: false },
        { key: "is_percentage", sortable: false },
        { key: "expiry", sortable: false },
        { key: "created_at", sortable: false },
        { key: "updated_at", sortable: false },
        { key: "applicable_for", sortable: false },
      ],
      rows: 0,
      perPage: 10,
      selectedCoupon: null,
      currentPage: 1,
      isPercentage: false,
      applicableFor: {
        transaction: false,
        processing: false,
        shipping: false,
      },
      couponCode: null,
      expiryDate: null,
      value: null,
    };
  },
  computed: {},
  mounted() {
    this.loadCouponData();
  },
  watch: {
    currentPage() {
      this.loadCouponData();
    },
  },
  methods: {
    loadCouponData() {
      CouponsServiceApi.getAllCoupons(this.currentPage).then((response) => {
        if (response.data) {
          this.couponsData = response.data.coupons.data;
          this.rows = response.data.coupons.total;
          console.log(response.data.coupons);
        }
      });
    },
    couponClicked(val) {
      this.selectedCoupon = val;
      this.applicableFor.transaction = val.is_transaction;
      this.applicableFor.processing = val.is_processing;
      this.applicableFor.shipping = val.is_shipping;
      this.couponCode = val.code;
      this.expiryDate = this.moment(val.expiry).format("YYYY-MM-DD");
      this.value = val.value;
      this.isPercentage = val.is_percentage;
      this.$refs.EditCoupon.show();
    },
    submitCouponHandler() {
      this.submitForm();
    },
    resetForm() {
      this.$refs.EditCoupon.hide();
      this.resetFormData();
    },
    deleteCoupon(item) {
      console.log(item);
      this.selectedCoupon = item;
      this.$refs.DeleteCoupon.show();
    },
    closeDeleteModal() {
      this.selectedCoupon = null;
      this.$refs.DeleteCoupon.hide();
    },
    addCoupon() {
      this.resetFormData();
      this.$refs.EditCoupon.show();
    },
    resetFormData() {
      this.applicableFor = {
        transaction: false,
        processing: false,
        shipping: false,
      };
      this.isPercentage = false;
      this.selectedCoupon = null;
      this.couponCode = null;
      this.expiryDate = null;
      this.value = null;
    },
    submitForm() {
      const dataSubmit = {
        code: this.couponCode,
        value: this.value,
        is_percentage: this.isPercentage,
        is_shipping: this.applicableFor.shipping,
        is_transaction: this.applicableFor.transaction,
        is_processing: this.applicableFor.processing,
        expiry: this.expiryDate,
      };
      dataSubmit.action = "add";
      if (this.selectedCoupon) {
        dataSubmit.id = this.selectedCoupon.id;
        dataSubmit.action = "edit";
      }

      CouponsServiceApi.couponAction(dataSubmit).then((response) => {
        if (response.data.success) {
          this.$refs.EditCoupon.hide();
          this.couponSuccessMessage(
            dataSubmit.action,
            response.data.coupon.code
          );
          this.loadCouponData();
          this.resetFormData();
        } else {
          this.couponErrorMessage(dataSubmit.action, response.data.error);
        }
      });
    },

    couponErrorMessage(action, error) {
      this.couponActionMessage(
        `Coupon ${action}`,
        `${action} is failed with the message : ${error}`,
        "danger"
      );
    },
    couponDeleteMessage() {
      this.couponActionMessage(
        `Coupon delete`,
        "Coupon is deleted successfully!"
      );
    },
    couponSuccessMessage(action, coupon) {
      this.couponActionMessage(
        `Coupon ${action}`,
        action === "edit"
          ? `Coupon ${coupon} updated successfully!`
          : `Coupon ${coupon} added successfully!`
      );
    },
    couponActionMessage(title, message, variant = "success") {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    removeCoupon(id) {
      const dataSubmit = {
        action: "delete",
        id: id,
      };
      CouponsServiceApi.couponAction(dataSubmit).then((response) => {
        if (response.data.success) {
          this.$refs.DeleteCoupon.hide();
          this.couponDeleteMessage();
          this.loadCouponData();
          this.resetFormData();
        } else {
          this.couponErrorMessage(dataSubmit.action, response.data.error);
        }
      });
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="coupon-nav-st">
              <b-button
                variant="light waves-effect waves-light float-right"
                @click="addCoupon"
              >
                <i class="ri-add-box-fill align-middle"></i> Add
                Coupon</b-button
              >
            </div>
            <div class="table-responsive mb-0">
              <b-table
                :items="couponsData"
                :fields="fields"
                responsive="sm"
                @row-clicked="couponClicked"
                selectable
              >
                <template v-slot:cell(expiry)="row">
                  <div v-if="row.value">
                    {{ moment(row.value).format("MMMM Do YYYY") }}
                    <br />
                    {{ moment(row.value).format("h:mm:ss a") }}
                  </div>
                </template>
                <template v-slot:cell(created_at)="row">
                  <div v-if="row.value">
                    {{ moment(row.value).format("MMMM Do YYYY") }}
                    <br />
                    {{ moment(row.value).format("h:mm:ss a") }}
                  </div>
                </template>
                <template v-slot:cell(updated_at)="row">
                  <div v-if="row.value">
                    {{ moment(row.value).format("MMMM Do YYYY") }}
                    <br />
                    {{ moment(row.value).format("h:mm:ss a") }}
                  </div>
                </template>
                <template v-slot:cell(is_percentage)="row">
                  <span v-if="row.value">
                    Yes
                  </span>
                  <span v-else>
                    No
                  </span>
                </template>
                <template v-slot:cell(applicable_for)="row">
                  <div v-if="row.item" style="width:140px;">
                    <div
                      class="row"
                      style="background-color: antiquewhite;
                          border-radius: 12px;"
                    >
                      <div class="col-8">
                        Transaction
                      </div>
                      <div class="col-4">
                        <i
                          class="ri-check-fill coupons-applicable "
                          v-if="row.item.is_transaction"
                        ></i>
                        <i
                          v-else
                          class="ri-close-fill coupons-not-applicable "
                        ></i>
                      </div>
                    </div>
                    <div
                      class="row"
                      style="background-color: #f1f1f1;
                          border-radius: 12px;margin-top:2px"
                    >
                      <div class="col-8">
                        Processing
                      </div>
                      <div class="col-4">
                        <i
                          class="ri-check-fill coupons-applicable "
                          v-if="row.item.is_processing"
                        ></i>
                        <i
                          v-else
                          class="ri-close-fill coupons-not-applicable "
                        ></i>
                      </div>
                    </div>
                    <div
                      class="row"
                      style="background-color:azure;
                            border-radius: 12px;margin-top:2px"
                    >
                      <div class="col-8">
                        Shipping
                      </div>
                      <div class="col-4">
                        <i
                          class="ri-check-fill coupons-applicable "
                          v-if="row.item.is_shipping"
                        ></i>
                        <i
                          v-else
                          class="ri-close-fill coupons-not-applicable "
                        ></i>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(code)="row">
                  <span style="font-size:14px;font-weight:900">
                    {{ row.value }} <br />
                    <button
                      class="btn btn-danger btn-sm"
                      style="height:20px;width:50px;padding:0px"
                      @click="deleteCoupon(row.item)"
                    >
                      Delete
                    </button>
                  </span>
                </template>
              </b-table>
            </div>

            <div class="row" style="">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination pagination-rounded mb-0 float-right">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="EditCoupon"
      id="EditCoupon"
      hide-footer
      centered
      title="Edit Coupon"
    >
      <FormulateForm name="couponForm" @submit="submitCouponHandler">
        <div class="m-2">
          <div class="row mb-4">
            <div class="col-6">
              <FormulateInput
                type="text"
                name="Code"
                label="Coupon"
                v-model="couponCode"
                validation="required"
                error-behavior="submit"
              />
            </div>
            <div class="col-6 ">
              <FormulateInput
                type="date"
                name="Expiry Date"
                v-model="expiryDate"
                label="Expiry Date"
                placeholder="Expiry Date"
                validation="required"
                error-behavior="submit"
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12" style="font-weight:900;margin-bottom:8px;">
              Applicable for
            </div>
            <div class="col-4">
              <FormulateInput
                v-model="applicableFor.transaction"
                type="checkbox"
                label="Transaction"
              />
            </div>
            <div class="col-4">
              <FormulateInput
                v-model="applicableFor.processing"
                type="checkbox"
                label="Proceesing"
              />
            </div>
            <div class="col-4">
              <FormulateInput
                v-model="applicableFor.shipping"
                type="checkbox"
                label="Shipping"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-4" style="padding-top:8px;font-weight:900">
              <div>
                Is percentage?
              </div>
              <div style="padding-top:10px">
                <b-form-checkbox
                  name="check-button"
                  v-model="isPercentage"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <div class="col-8">
              <FormulateInput
                type="number"
                name="Value"
                label="Value"
                v-model="value"
                validation="required"
                error-behavior="submit"
              />
            </div>
          </div>
        </div>
        <div style="padding-bottom:60px;">
          <div class="text-center m-4">
            <button
              class="btn btn-warning w-md waves-effect waves-light float-left"
              type="submit"
              v-if="selectedCoupon"
            >
              Update
            </button>
            <button
              class="btn btn-secondary w-md waves-effect waves-light float-left"
              type="submit"
              v-else
            >
              Add
            </button>

            <button
              class="btn btn-primary w-md waves-effect waves-light float-right"
              type="button"
              @click="resetForm"
            >
              Cancel
            </button>
          </div>
        </div>
      </FormulateForm>
    </b-modal>

    <b-modal
      ref="DeleteCoupon"
      id="DeleteCoupon"
      hide-footer
      centered
      title="Delete Coupon"
    >
      <div
        v-if="selectedCoupon"
        class="p-4"
        style="text-align:center;font-size:large"
      >
        Are you sure,do you want to delete the token
        <div style="font-weight:900">{{ selectedCoupon.code }} !</div>
      </div>
      <div style="padding-bottom:60px;">
        <div class="text-center m-4">
          <button
            class="btn btn-danger w-md waves-effect waves-light float-left"
            type="button"
            @click="removeCoupon(selectedCoupon.id)"
          >
            Delete
          </button>

          <button
            class="btn btn-primary w-md waves-effect waves-light float-right"
            type="button"
            @click="closeDeleteModal"
          >
            Cancel
          </button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>

<style>
.coupons-applicable {
  color: green;
  font-weight: 900;
  font-size: 18px;
}
.coupons-not-applicable {
  color: red;
  font-weight: 900;
  font-size: 18px;
}

#EditCoupon
  .formulate-input[data-classification="box"]
  .formulate-input-element {
  padding-top: 8px;
}

#EditCoupon .modal-title {
  margin-left: 185px;
  color: gray;
}

#DeleteCoupon .modal-title {
  margin-left: 185px;
  color: gray;
}
.coupon-nav-st {
  margin-bottom: 60px;
}
</style>
